import axios from 'axios';
import { debugError } from "./errors";
import { BASE_URL } from "./config";

export const getInvoices = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/invoice`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
}

export const createInvoice = async ({ token, order, from, to, amount, currency_id = 1, text = "" }) => {
  console.log("New invoice", { order, from, to, amount });

  try {
    const headers = { 'Authorization': `Bearer ${token}` };
    const agentByPhone = await axios.get(`${BASE_URL}/api/v1/client?phone=${to}`, { headers });
    const { id: payee } = agentByPhone.data;
    if (!payee) throw ({ error_code: 0, message: "Не удалось получить id кошелька получателя" });

    const invoiceBody = {
      order_number: null,
      wallet_id: from,
      payer_agent: payee,
      amount: amount * 100,
      currency_id,
      metadata: {
        description: "Invoice from WallletApp merchant GUI",
        text
      }
    };
    
    const invoice = await axios({ method: 'post', url: `${BASE_URL}/api/v1/invoice`, headers, data: invoiceBody, })
    const { id: invoiceID, status: invoiceStatus } = invoice.data;
    if (!invoiceID) throw ({ error_code: 0, message: "Не удалось создать транзакцию на перевод" });
    if (!invoiceStatus === 400) throw ({ error_code: 0, message: "Не удалось создать инвойс" });
    return invoice.data;

  } catch (error) {
    debugError(error);
    throw error;
  }
}