import styles from "./invoices.module.css";
import Invoice from "./Invoice";
import { Link } from 'react-router-dom';
import btn from "components/Button/button.module.css";

export default ({ list }) => {
    return (
        <>
            <div className={styles.header}>
                <h1>{getInvoiceTitle(list.length)}</h1>
                <Link className={`${btn.button} ${btn.light}`} to="/invoice">+ Создать</Link>
            </div>
            { list && list.length > 0 && <Invoices list={list} /> }
        </>
    );
}

const InvoiceItem = (invoice, index) => {
    return (
        <Invoice key={invoice.id}
            id={invoice.id}
            index={index}
            amount={invoice.amount}
            phone={invoice.payer_agent.phone}
            status={invoice.status}
            transaction={invoice.transaction}
            created={invoice.created_at}
        />
    )
}


const Invoices = ({ list = [] }) =>
(
    <table className={styles.table}>
        <thead>
            <tr>
                <th scope="col">Клиент</th>
                <th scope="col">Сумма</th>
                <th scope="col">Дата</th>
                <th scope="col">Статус</th>
                <th scope="col">Оплата</th>
            </tr>
        </thead>
        <tbody>
            {list.map(InvoiceItem)}
        </tbody>
    </table>
);

function getInvoiceTitle(count) {
    if (count === 0) return "Инвойсов нет";

    let title = "";

    let lastDigit = count % 10;
    let lastTwoDigits = count % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        title += count + " инвойсов";
    } else if (lastDigit === 1) {
        title += count + " инвойс";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        title += count + " инвойса";
    } else {
        title += count + " инвойсов";
    }


    return title;
}