export const dictionary = {
  0: {
    title: "Неизвестная ошибка",
    message: "Сделайте скриншот и отправьте его в нашу тех. поддержку"
  },

  3: {
    title: "Неверный логин или пароль",
    message: "Проверьте правильно ли вы вводите данные. Если вы ранее не регистрировались, то создайте новый кошелёк."
  },

  9: {
    title: "Ошибка 9",
    message: "Что-то на форме было заполнено неправильно"
  },

  12: {
    title: "Ошибка перевода",
    message: "На вашем кошельке недостаточно средств"
  },

  30: {
    title: "Ошибка 30",
    message: "Сумма должна быть больше 1₸"
  },

  32: {
    title: "Ошибка 32",
    message: "Попытка работать с чужим кошельком"
  },

  37: {
    message: "На этот номер никто не зарегистрирован"
  },

  39: {
    message: "На один и тот же номер можно отправлять только одно сообщение в минуту"
  },

  42: {
    message: "Кошелек на такой номер не зарегистрирован"
  },

  43: {
    title: "Не удалось создать новый кошелёк",
    message: "Пользователь с таким номером телефона уже зарегистрирован"
  },

  44: {
    title: "Ошибка 44",
    message: "Сессия недействительна"
  },

  2000: {
    title: "Ошибка 2000",
    message: "Номер телефона не заполнен"
  },

  2002: {
    title: "Код недействителен",
    message: "У кода, который вы вводите, вероятно уже истёк срок действия. Попробуйте вернуться на предыдущий шаг и отправить новый код"
  },

  2003: {
    message: "Пользователь с таким номером телефона уже зарегистрирован"
  },

  2011: {
    title: "Ошибка регистрации",
    message: "Пользователь с таким номером телефона уже зарегистрирован"
  },

  2013: {
    title: "Ошибка 2013",
    message: "Неверный код"
  },

  2014: {
    message: "На один и тот же номер можно отправлять только одно сообщение в минуту"
  }
};

export const getErrorInfo = code => {
    if (dictionary[code]) return dictionary[code];
    return dictionary[0];
  };

export const debugError = error => {
    // Токен истёк
    if (error.response?.data?.error_code === 44) {
      localStorage.removeItem('Bearer');
      window.location.href = "/";
      return;
    }
  
    if (error.response) {
      console.log('Запрос был сделан и сервер ответил кодом статуса, который выходит за пределы диапазона 2xx');
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log('Запрос был сделан, но ответа не было');
      console.log(error);
    } else {
      console.log('Что-то произошло при настройке запроса, что вызвало ошибку');
      console.log('Error', error.message);
    }
  }